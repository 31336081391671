
<div id="contact">

    <div class="contact-content container">
        <div class="line"></div>
        <h1 class="title">Contact</h1>
        
        
        <div class="flex-area">
            
            
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" method="POST" class="contact-form">

                <p class="success-msg" ><fa-icon [icon]="faCheckCircle"></fa-icon> Email has been sent!</p> 
                <div>
                    <label for="name">name</label>
                    <input type="text" formControlName="name">
                    <span *ngIf="contactForm.get('name').dirty && contactForm.get('name').hasError('required')" class="form-error">Name is required</span>
                </div>

                <div>
                    <label for="subject">subject</label>
                    <input type="text" formControlName="subject">
                    <span *ngIf="contactForm.get('subject').dirty && contactForm.get('subject').hasError('required')" class="form-error">Subject is required</span>
                </div>
        
                <div>
                    <label for="email">email</label>
                    <input type="email" formControlName="email">
                    <span *ngIf="contactForm.get('email').dirty && contactForm.get('email').hasError('required')" class="form-error">Email is required</span>
                    <span *ngIf="contactForm.get('email').touched && contactForm.get('email').hasError('email')" class="form-error">Enter valid email address</span>
                    <span *ngIf="contactForm.get('email').touched && contactForm.get('email').hasError('minlength')" class="form-error">Email must be at least 5 characters</span>
                </div>
                
                <div>
                    <label for="message">message</label>
                    <textarea name="message" cols="30" rows="10" formControlName="message"></textarea>
                    <span *ngIf="contactForm.get('message').dirty && contactForm.get('message').hasError('required')" class="form-error">Message is required</span>
                    <span *ngIf="contactForm.get('message').touched && contactForm.get('message').hasError('minlength')" class="form-error">Message must be at least 10 characters</span>
                </div>

                <!-- <button class="button-red" (click)="executeImportantAction()" [disabled]="!contactForm.valid">Send</button> -->
                <button class="button-red" [disabled]="!contactForm.valid">Send</button>
            </form>
             





            <div class="my-info">
                <div class="left">
                    <!-- <div class="contact-info">
                        <h2>Contact Information</h2>
                        <p>Any questions or remarks? Fill the form and I will get back to you in 24 hours?</p>
                    </div> -->
                    <div class="info">
                        <h3>e-mail</h3> 
                        <p> <a href="mailto:nricaldi.nr@gmail.com">nricaldi.nr@gmail.com</a></p>
                    </div>
                
                    <div class="info">
                        <h3>number</h3>
                        <p> <a href="tel: +1-571-288-4243">571-288-4243</a></p>
                    </div>

                </div>

                <div class="right">
                    <div class="info">
                        <h3>social</h3>
                        <p>
                            <a href="https://www.linkedin.com/in/nicolas-ricaldi/" target="_blank"><fa-icon class="icons" [icon]="faLinkedin" ></fa-icon></a>
                            <a href="https://github.com/nricaldi" target="_blank"><fa-icon class="icons" [icon]="faGithub"></fa-icon></a>
                            <a href="https://dribbble.com/nricaldi" target="_blank"><fa-icon class="icons" [icon]="faDribbble"></fa-icon></a>
                        </p>
                    </div>
                
                    <div class="info">
                        <h3>location</h3>
                        <p>D.C Metro Area</p>
                    </div>

                </div>
            
            </div>





            
        </div>
        
    </div>

</div>