<div id="home">

    <div class="main">
        <h1 id="nicolas">Nicolas</h1>
        <h1 id="ricaldi">Ricaldi</h1>
    



        <!-- <div class="hero" @fade> -->
        <div class="hero">
        <!-- <div class="hero" [@stagger]="3"> --> 
            <!-- <div> -->
                <!-- “Innovative development stacked up to perfection” -->

            <h1>Innovative development stacked up to perfection</h1>
            <!-- <h1>Innovative development</h1> -->
            <!-- <h2>Stacked up to perfection</h2> -->
            <!-- </div> -->
            <h3>Full Stack Developer</h3>
            <!-- <h3 >Full Stack Developer</h3> -->
            <div class="buttons" >
                <button class="button-red" (click)="scrollToElement('work')">My work</button>
                <button class="button-gray" (click)="scrollToElement('contact')">Hire Me</button>
            </div>

         </div> 

    </div>  

</div>  


   