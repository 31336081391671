
<div id="about">    
    <div class="about-content container">
        <div class="line"></div>
        <h1 class="title">About</h1>

        <div class="about-info">
            <div class="who about-anim">
                <h3>Who am I?</h3>
                <p>I am a full stack developer based in northern Virginia. I have a computer science background and a coding bootcamp graduate.</p>
            
            </div>
    
            <div class="what about-anim">
                <h3>What do I do?</h3>
                <!-- <p>I build full stack applications from concept to completion. From designing to developing and deploying. I can do it all!</p>     -->
                <p>I have been developing web applications for over 2 years. During this time, I have completed projects ranging from a blogs to large scale ecommerce applications.</p>    
            </div>
        </div>
      </div>
</div>  