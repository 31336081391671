
<div id="footer">

    <!-- <div>
        <h3>email</h3>
        <p>nricaldi.nr@gmail.com</p>
    </div>

    <div>
        <h3>number</h3>
        <p>571-288-4243</p> 
    </div>

    <div>
        <h3>social</h3>
        <p>L G I</p>
    </div>

    <div>
        <h3>location</h3>
        <p>D.C Metro Area</p>
    </div> -->


    <div class="footer-content">
        <ul>
            <li (click)="scrollToElement('home')"><a routerLink="">home</a></li>
            <li (click)="scrollToElement('about')"><a routerLink="">about</a></li>
        </ul>

        <div class="copyright">
            <!-- <img src="../../assets/img/logo-blue.svg" alt="logo" class="logo-blue"> -->
            <p>	&copy; Copyright Nicolas Ricaldi {{ year }}</p>
        </div>


        <ul>
            <li (click)="scrollToElement('work')"><a routerLink="">work</a></li>
            <li (click)="scrollToElement('contact')"><a routerLink="">contact</a></li>
        </ul>
    </div>


</div>