
<div id="work">

    <div class="work-content container">

        <div class="line"></div>
        <h1 class="title">Work</h1>
         
        <div class="proj-grid">

            <div class="proj proj-1 anim" (mouseenter)="showContent('proj-1')" (mouseleave)="showContent('proj-1')" data-delay="0s">
                <div class="proj-content">
                    <h3>INI Services</h3>
                    <button class="button-red" routerLink="project/1">Learn more</button>
                </div>
            </div>
        
            <div class="proj proj-2 anim" (mouseenter)="showContent('proj-2')" (mouseleave)="showContent('proj-2')" data-delay=".5s">
                <div class="proj-content">
                    <h3>Travel Buddy</h3>
                    <button class="button-red" routerLink="project/2">Learn more</button>
                </div>
            </div>
        
            <div class="proj proj-3 anim" (mouseenter)="showContent('proj-3')" (mouseleave)="showContent('proj-3')" data-delay=".7s">
                <div class="proj-content">
                    <h3>Movie Rater</h3>
                    <button class="button-red" routerLink="project/3">Learn more</button>
                </div>
            </div>
        
            <div class="proj proj-4 anim" (mouseenter)="showContent('proj-4')" (mouseleave)="showContent('proj-4')" data-delay="1s">
                <div class="proj-content">
                    <h3>BT Real Estate</h3>
                    <button class="button-red" routerLink="project/4">Learn more</button>
                </div>
            </div>
        </div>

    </div>

</div>   